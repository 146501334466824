/* eslint-disable prettier/prettier */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { CartItem, ICart, ICartFilter, ICartItem } from "../models/cart.model";
import { catchError, first, map, switchMap, tap } from "rxjs/operators";
import { selectOptions } from "@portal/shared/functions/get-select-options";
import { BaseResourceService } from "@portal/shared/services/http/base-resource.service";
import { dummyErrorResponse } from "@portal/shared/constants/common.constants";

@Injectable({
    providedIn: 'root'
})
export class CartService extends BaseResourceService {
    private myCart$ = new BehaviorSubject<ICart | undefined>(undefined);
    private cartCounts$ = new BehaviorSubject<number>(0);

    constructor(
        httpClient: HttpClient,
    ) {
        super(httpClient);
        this.resourceUrl = 'cart/items';
    }

    public cartItemsChanged$(): Observable<ICart | undefined> {
        return this.cartCounts$.asObservable().pipe(
            switchMap(() => (this.getMyCartValue$()))
        );
    }

    private getMyCartValue$(): Observable<ICart | undefined> {
        if (this.myCart$.getValue() === undefined) {
            return this.getMyCart().pipe(
                map((res) => <ICart>res.cart),
                tap((cartInfo) => {
                    if (cartInfo?.items?.length) {
                        cartInfo.items = cartInfo.items.map((item: ICartItem) => {
                            return {
                                ...item,
                                selectedSchools: selectOptions(item?.forSchools || item?.canteenSubscriptionForSchools || []),
                                selectedCanteens: selectOptions(item?.forCanteens || []),
                            } as CartItem;
                        })

                        sessionStorage.setItem('myCart', JSON.stringify(cartInfo));
                    }
                    this.myCart$.next(cartInfo);
                }),
                first(),
            )
        } else {
            return this.myCart$.asObservable();
        }
    }

    public getCartItemsCount(): number {
        return this.cartCounts$.getValue() || 0;
    }

    public numberOfCartItems$(): Observable<number> {
        if (this.cartCounts$.getValue() === undefined) {
            return this.getList(({ isCountOnly: true} as ICartFilter)).pipe(
                map((res) => <number>(res?.cartCounts || 0)),
                tap((count) => this.cartCounts$.next(count)),
                first()
            )
        } else {
            return this.cartCounts$.asObservable()
        }
    }

    public updateCartCounts(counts = 0): void {
        this.myCart$.next(undefined);
        this.cartCounts$.next(counts);
    }

    public resetCart(): void {
        this.myCart$.next(undefined);
        this.cartCounts$.next(0);
    }

    public addItemToCart(items: ICartItem[]): Observable<IApiResult> {
        return this.httpClient.post<IApiResult>(`/api/v3/cart/items`, { items });
    }

    public getMyCart(): Observable<IApiResult> {
        return this.getList().pipe(catchError(() => of({ ...dummyErrorResponse, cart: undefined })));
    }

    public updateCartItem(id: string, payload: any): Observable<IApiResult> {
        return this.v2Patch(`/api/v3/cart/items/${id}`, payload);
    }

    public deleteCartItem(id: string): Observable<IApiResult> {
        return this.v2Delete(`/api/v3/cart/items/${id}`);
    }
}