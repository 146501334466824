/* eslint-disable prettier/prettier */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IApiResult } from '@portal/shared/models/api-result.model';
import { IOtpSignUp } from "../models/sign-up";
import { IOtpLoginResult } from "@portal/auth/models/login-result.model";
import { defaultUser, IUser } from "@portal/shared/models/user.model";
import { CookieService } from "ngx-cookie-service";
import { IBUserEmail, INewBusinessProfile, INewBusinessUser } from "../models/business-user";

const B_JWT_TOKEN = `businessjwttoken`;
@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    bUser: IUser = defaultUser;
    bUserEmail: string = '';
    alert: string = 'Something went wrong, please try again';

    constructor(
        private httpClient: HttpClient,
        private cookieService: CookieService
    ) { }

    isNullOrEmpty = (value: string | undefined | null) => {
        return !value || value === undefined || (value && value.trim() === '');
    };

    // School
    businessSignUp = (user: INewBusinessUser): Observable<IApiResult> => {
        return this.httpClient.post<IApiResult>(`api/v3/schools/signup`, user);
    };

    businessVerifiedOTP = (code: IOtpSignUp): Observable<IOtpLoginResult> => {
        return this.httpClient.post<IOtpLoginResult>(`api/v3/schools/signup-otp-verification`, code);
    };

    postBusinessProfile = (profile: INewBusinessProfile, addressProofCert: Blob, bizCert: Blob, vatCert?: Blob): Observable<IApiResult> => {
        const formData = new FormData();

        if (profile.vat && vatCert) {
            formData.append('vatCert', vatCert!, 'vatCert.pdf');
        }

        formData.append('addressProofCert', addressProofCert, `addressProofCert.pdf`);
        formData.append('bizCert', bizCert, 'bizCert.pdf');
        formData.append('postData', JSON.stringify(profile));

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.httpClient.post<IApiResult>(
            `api/v3/schools/profile`, formData, { headers: headers.append(B_JWT_TOKEN, `${this.getToken()}`) }
        );
    };

    // Canteen
    canteenSignUp = (user: INewBusinessUser): Observable<IApiResult> => {
        return this.httpClient.post<IApiResult>(`api/v3/canteens/signup`, user);
    };

    canteenOtp = (code: IOtpSignUp): Observable<IOtpLoginResult> => {
        return this.httpClient.post<IOtpLoginResult>(`api/v3/canteens/signup-otp-verification`, code);
    };

    postCanteenProfile = (canteen: INewBusinessProfile, addressProofCert: string | Blob, bizCert: string | Blob, vatCert?: string | Blob): Observable<IApiResult> => {
        const formData = new FormData();

        if (canteen.vat && vatCert) {
            formData.append('vatCert', vatCert!);
        }

        formData.append('addressProofCert', addressProofCert);
        formData.append('bizCert', bizCert);
        formData.append('postData', JSON.stringify(canteen));

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.httpClient.post<IApiResult>(
            `api/v3/canteens/profile`, formData, { headers: headers.append(B_JWT_TOKEN, `${this.getToken()}`) }
        );
    };

    getSchools = (select: string[]): Observable<IApiResult> => {
        let uri = `api/v3/schools?select=${(select || []).join(',')}&isFromBizRegistration=true`;

        return this.httpClient.get<IApiResult>(uri, { headers: new HttpHeaders().append(B_JWT_TOKEN, `${this.getToken()}`) });
    };

    getCanteens = (select: string[]): Observable<IApiResult> => {
        let uri = `api/v3/canteens?select=${(select || []).join(',')}&isFromBizRegistration=true`;

        return this.httpClient.get<IApiResult>(uri, { headers: new HttpHeaders().append(B_JWT_TOKEN, `${this.getToken()}`) });
    }

    setUser = (user: IUser) => {
        this.bUser = { ...this.bUser, ...user };

        localStorage.setItem('businessUser', JSON.stringify(this.bUser));
    };

    get localStorageBUser(): IUser | null {
        const businessUser = localStorage.getItem('businessUser');

        if (businessUser === 'undefined') {
            return null;
        }

        return JSON.parse(businessUser!) as IUser;
    }

    loadEmailFromStorage = () => {
        return of(this.localEmail);
    };

    get localEmail(): IBUserEmail | null {
        const emailRaw = localStorage.getItem('businessUserEmail');

        if (emailRaw === 'undefined') {
            return null;
        }

        return JSON.parse(emailRaw!);
    }

    setUserEmail = (email: string) => {
        this.bUserEmail = email;
        this.bUser = { ...this.bUser, email: email };
        localStorage.setItem(
            'businessUserEmail',
            JSON.stringify({ email: email })
        );
    };

    clearUserEmail = () => {
        this.bUserEmail = '';
        localStorage.removeItem('businessUserEmail');
    };

    setStorageUser = (user: IUser) => {
        this.bUser = user;
        localStorage.setItem('businessUser', JSON.stringify(user));
    };

    clearStorage = () => {
        this.clearUser();
        this.clearUserEmail();
        localStorage.removeItem('businessUserType');
    }

    setToken = (token: string) => {
        this.cookieService.set(B_JWT_TOKEN, token);
    };

    clearUser = () => {
        this.bUser = defaultUser;
        localStorage.removeItem('businessUser');
        this.cookieService.delete(B_JWT_TOKEN);
    };

    getToken = (): string | null | undefined => {
        const user = this.localStorageBUser;
        return user && user.token;
    };
}