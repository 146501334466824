import { HttpUrlEncodingCodec } from "@angular/common/http";
import { ILoginResult, IOtpLoginResult } from "@portal/auth/models/login-result.model";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { Credentials, IForgotPassword, ILoginOTP } from "@portal/shared/models/user.model";
import { Observable } from "rxjs";

export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
    encodeKey(k: string): string {
        k = super.encodeKey(k);
        return k.replace(/\+/gi, '%2B');
    }
    encodeValue(v: string): string {
        v = super.encodeValue(v);
        return v.replace(/\+/gi, '%2B');
    }
}

export abstract class IAuthLoginService {
    abstract loginByEmail(credentials: Credentials): Observable<ILoginResult>;
    abstract verifyLoginOTP(loginOtp: ILoginOTP): Observable<IOtpLoginResult>;
    abstract resendLoginOTP(loginOtp: ILoginOTP): Observable<IOtpLoginResult>;
    abstract forgotPassword(request: IForgotPassword, step: string): Observable<IApiResult>;
    abstract getMyProfile(): Observable<IApiResult>;
}
