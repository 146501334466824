/* eslint-disable prettier/prettier */
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './shared/functions/custom-url-serilizer';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AuthGuard } from './auth/services/auth-guard.service';
import { AuthService } from './auth/services/auth.service';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { createTranslateLoader } from './shared/services/language/language.function';
import { environment } from 'src/environments/environment';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { DefaultLanguage, LanguageKey } from './shared/services/language/language.model';

const config: SocketIoConfig = {
    url: environment.SOCKET_URL,
    options: {
        query: { token: AuthService.getCurrentUserToken() },
        transports: ['websocket', 'polling']
    }
};

const getBaseLang = () => {
    return localStorage.getItem(LanguageKey) ?? DefaultLanguage;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule,
        CoreModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            progressBar: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgbModule,
        SharedModule,
        NgMultiSelectDropDownModule.forRoot(),
        SocketIoModule.forRoot(config)
    ],
    providers: [
        { provide: 'environment', useValue: environment },
        AuthService,
        AuthGuard,
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        { provide: LOCALE_ID, useValue: 'en-US' },

        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: getBaseLang,
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
