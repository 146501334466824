/* eslint-disable prettier/prettier */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from '@portal/app.component';
import { AuthService } from '@portal/auth/services/auth.service';
import { CartDetailsDialogComponent } from '@portal/business-store/cart-details-dialog/cart-details-dialog.component';
import { ICart } from '@portal/business-store/shared/models/cart.model';
import { CartService } from '@portal/business-store/shared/services/cart.service';
import { ICanteen } from '@portal/canteen/models/canteen.model';
import { CanteenService } from '@portal/canteen/services/canteen.service';
import { ISchool } from '@portal/school/models/school.model';
import { SchoolService } from '@portal/school/services/school.service';
import { DEFAULT_SELECT_FIELDS } from '@portal/shared/constants/common.constants';
import { selectOptions } from '@portal/shared/functions/get-select-options';
import { ISelectOption } from '@portal/shared/models/select-option.model';
import { defaultUser } from '@portal/shared/models/user.model';
import { ArabicLanguage, DefaultLanguage } from '@portal/shared/services/language/language.model';
import { LanguageService } from '@portal/shared/services/language/language.service';
import { NotifyService } from '@portal/shared/services/notify.service';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
    @Input() showSidebar = false;
    @Output() toggleSidebar = new EventEmitter<void>();
    @Output() logout = new EventEmitter<void>();

    public app = AppComponent;
    public isProduction = environment.production;
    public webVersion = `v${environment.version}`;
    public currentLanguage = localStorage.getItem('lang') || DefaultLanguage;
    public isAdmin = false;
    public isCallSystemUser = false;
    public isLoadingCart = true;
    public cartCounts = 0;
    public notificationCounts = null;
    public currentUser = defaultUser;

    private cart: ICart | undefined;
    private schoolOptions: ISelectOption[] = [];
    private canteenOptions: ISelectOption[] = [];
    private subscription = new Subscription();

    constructor(
        private modalService: NgbModal,
        private authService: AuthService,
        private cartService: CartService,
        private schoolService: SchoolService,
        private canteenService: CanteenService,
        private notify: NotifyService,
        private languageService: LanguageService
    ) {
        this.isAdmin = this.authService.isAdminOrSuperAdmin();
        this.isCallSystemUser = this.authService.isCallSystemUser();
    }

    ngOnInit(): void {
        this.currentUser = this.authService.user;
        this.cartService.resetCart();
        this.initDropdownItemsForCart();
        if (!this.isCallSystemUser) {
            this.cartService.numberOfCartItems$().subscribe((value) => {
                this.cartCounts = value || 0;
                this.getMyCart();
            })
        }
    }

    public onChangedLanguage = (): void => {
        if (this.currentLanguage === DefaultLanguage) {
            this.languageService.updateLanguage(ArabicLanguage)
            this.currentLanguage = ArabicLanguage;
        } else {
            this.languageService.setDefault();
            this.currentLanguage = DefaultLanguage;
        }
    }

    public goToCart = (): void => {
        if (this.isLoadingCart) {
            return;
        }

        let modal = this.modalService.open(CartDetailsDialogComponent, {
            animation: true,
            backdrop: 'static',
            centered: true,
            scrollable: true,
            size: 'xl',
            windowClass: 'col-md-12'
        });

        modal.componentInstance.isAdmin = this.isAdmin;
        modal.componentInstance.isCanteenUser = this.authService.isCanteenOrSuperCanteen();
        modal.componentInstance.isLoading = this.isLoadingCart;
        modal.componentInstance.myCart = this.cart;
        modal.componentInstance.cartCounts = this.cartCounts;
        modal.componentInstance.schoolOptions = this.schoolOptions;
        modal.componentInstance.canteenOptions = this.canteenOptions;
    }

    private getMyCart(): void {
        this.isLoadingCart = true;
        this.subscription.add(
            this.cartService.cartItemsChanged$()
                .subscribe({
                    next: (info) => {
                        this.cartCounts = this.cartService.getCartItemsCount();
                        this.cart = info;
                        this.isLoadingCart = false;
                    },
                    error: (error) => {
                        this.notify.error('Cart Items', 'Failed to retrieve data', error);
                        this.isLoadingCart = false;
                    }
                })
        );
    }

    private getSelectSchools(): Observable<ISchool[]> {
        if (!this.authService.isCanteenOrSuperCanteen() && !this.authService.isAdminOrSuperAdmin()) {
            return of([]);
        }
        return this.schoolService.getSelectSchools(DEFAULT_SELECT_FIELDS, this.authService.getUserCanteenId()).pipe(
            map((res) => (res.success ? (res.schools || []) : [])),
            catchError(() => of([]))
        )
    }

    private getSelectCanteens(): Observable<ICanteen[]> {
        if (!this.authService.isAdminOrSuperAdmin()) {
            return of([]);
        }
        return this.canteenService.getSelectCanteens(DEFAULT_SELECT_FIELDS).pipe(
            map((res) => (res.success ? (res.canteens || []) : [])),
            catchError(() => of([]))
        )
    }

    private initDropdownItemsForCart(): void {
        forkJoin({
            schools: this.getSelectSchools(),
            canteens: this.getSelectCanteens()
        }).subscribe(({ schools, canteens }) => {
            this.schoolOptions = selectOptions(schools);
            this.canteenOptions = selectOptions(canteens);
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
