import { environment } from 'src/environments/environment';

export function getBaseUrl() {
    return environment.APP_BASE_PATH ?? document.getElementsByTagName('base')[0].href;
}

export function getServerUrl() {
    return environment.API_BASE_PATH;
}

export function getImageServerUrl() {
    return environment.IMAGE_BASE_PATH;
}

export function getSocketUrl() {
    return environment.SOCKET_URL;
}
