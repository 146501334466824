import { IApiResult } from "../models/api-result.model";

/* eslint-disable prettier/prettier */
export const EMPTY_ID = '';
export const LOCAL_DATE_WITH_TIME = 'DD/MM/YYYY HH:mmA';
export const LOCAL_DATE = 'DD/MM/YYYY';
export const LOCAL_DATE_FOR_INPUT = 'YYYY-MM-DD';
export const LOCAL_DATE_WITH_TIME_FOR_INPUT = 'YYYY-MM-DDThh:mm';
export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const UPLOAD_FILE_SIZE_LIMIT_IN_BYTE = 4000000;
export const UPLOAD_FILE_SIZE_LIMIT_MEDIA_IN_BYTE = 10000000; //10MB
export const MAX_SIZE_AFTER_COMPRESSION_IN_MB = 2;
export const ACCEPTED_FILE_FORMATS = ['jpeg', 'jpg', 'png', 'csv', 'pdf'];
export const IMAGE_FORMATS = ['jpeg', 'jpg', 'png'];
export const MAX_CHECKLIST_TAG_NUMBER = 5;
export const X_TOTAL_COUNT = 'x-total-count';
export const WAKI_VAT = '311365691600003';
export const COMPANY_NAME = 'WAKI ITS Co.';
export const COMPANY_ADDRESS = '3080 Yanbu Street, Al Faisaliyah Dist., Jeddah 23442-9046 Saudi Arabia';

export const DEFAULT_SELECT_FIELDS = ['_id', 'id', 'translations'];

export const emptyArray: any[] = [];
export const dummyErrorResponse = {
    success: false,
    msg: 'common.error_message.retrieve_data_fail',
} as IApiResult;

export class CheckboxValues {
    public static Checked = 'Checked';
    public static NotChecked = 'Not checked';
}
