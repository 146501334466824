import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public defaultHeaders = new HttpHeaders();
  /**
   * The API endpoint URL as defined in the environment config.
   */
  protected basePath = `/api/v${environment.API_VERSION}`;

  constructor(
    protected httpClient: HttpClient
  ) { }

  /**
   * 
   * 
   * @param url - the url of the request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public v2Get(
    url: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IApiResult>;
  public v2Get(
    url: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IApiResult>>;
  public v2Get(
    url: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IApiResult>>;
  public v2Get(
    url: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    return this.httpClient.request<IApiResult>(
      'get',
      `${this.concatUrl(url)}`,
      {
        headers: this.defaultHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * 
   * 
   * @param url - the url of the request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public v2Post(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IApiResult>;
  public v2Post(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IApiResult>>;
  public v2Post(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IApiResult>>;
  public v2Post(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (!headers) {
      headers = this.defaultHeaders;
    }
  
    return this.httpClient.request<IApiResult>(
      'post',
      `${this.concatUrl(url)}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * 
   * 
   * @param url - the url of the request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public v2Patch(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IApiResult>;
  public v2Patch(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IApiResult>>;
  public v2Patch(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IApiResult>>;
  public v2Patch(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (!headers) {
      headers = this.defaultHeaders;
    }

    return this.httpClient.request<IApiResult>(
      'patch',
      `${this.concatUrl(url)}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  public v2Put(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IApiResult>;
  public v2Put(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IApiResult>>;
  public v2Put(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IApiResult>>;
  public v2Put(
    url: string,
    body: any,
    headers?: HttpHeaders,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (!headers) {
      headers = this.defaultHeaders;
    }

    return this.httpClient.request<IApiResult>(
      'put',
      `${this.concatUrl(url)}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
  /**
   * 
   * 
   * @param url - the url of the request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public v2Delete(
    url: string,
    headers?: HttpHeaders,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IApiResult>;
  public v2Delete(
    url: string,
    headers?: HttpHeaders,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IApiResult>>;
  public v2Delete(
    url: string,
    headers?: HttpHeaders,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IApiResult>>;
  public v2Delete(
    url: string,
    headers?: HttpHeaders,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (!headers) {
      headers = this.defaultHeaders;
    }

    return this.httpClient.request<IApiResult>(
      'delete',
      `${this.concatUrl(url)}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Sending GET requests operations to the server.
   * @param url - the url of the request
   * @protected
   */
  protected get(url: string) {
    const requestUrl = this.concatUrl(url);
    return this.httpClient.get<IApiResult>(requestUrl, { observe: 'response' });
  }

  /**
   * Sending POST requests operations to the server.
   * @param url
   * @param body
   * @param headers
   * @protected
   */
  protected post(url: string, body: any, headers?: HttpHeaders) {
    const requestUrl = this.concatUrl(url);
    return this.httpClient.post(requestUrl, body, { headers: headers, reportProgress: true, observe: 'events' });
  }

  /**
   * Sending PUT requests operations to the server.
   * @param url
   * @param body
   * @param headers
   * @protected
   */
  protected put(url: string, body: any, headers?: HttpHeaders) {
    const requestUrl = this.concatUrl(url);
    return this.httpClient.put(requestUrl, body, { headers: headers });
  }

  protected patch(url: string, body: any, headers?: HttpHeaders) {
    const requestUrl = this.concatUrl(url);
    return this.httpClient.patch(requestUrl, body, { headers: headers });
  }

  /**
   * Sending DELETE requests operations to the server.
   * @param url
   * @protected
   */
  protected delete(url: string, options?: any) {
    const requestUrl = this.concatUrl(url);
    return this.httpClient.delete(requestUrl, options as any);
  }

  /**
   * Concatenate the API base URL to the request URL.
   * @param url
   * @private
   */
  private concatUrl(url: string) {
    if (!url) {
      return this.basePath;
    }
    return `${this.basePath}/${url}`;
  }
}
