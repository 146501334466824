<ng-select
    [labelForId]="id || formControlName"
    [attr.name]="formControlName"
    [(ngModel)]="value"
    [addTag]="false"
    [minTermLength]="selectConfig.minTermLength || 3"
    [loading]="searching"
    [placeholder]="placeHolderText | translate"
    [typeToSearchText]="typeToSearchText | translate"
    [loadingText]="loadingText | translate"
    [notFoundText]="notFoundText | translate"
    [typeahead]="input$"
    [disabled]="disabled"
    [multiple]="!!selectConfig.multiple"
    (change)="change.emit()"
    *ngIf="selectConfig"
    class="ng-select-sm"
>
    <ng-option *ngFor="let item of data$ | async" [value]="fieldValue(item)">
        <ng-container *ngIf="labelFieldName == 'translations'">
            {{ fieldText(item) | translation }}
        </ng-container>
        <ng-container *ngIf="labelFieldName != 'translations'">
            {{ fieldText(item) | translate }}
        </ng-container>
    </ng-option>
</ng-select>
