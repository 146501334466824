import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IAdultSignupRequest,
    IAdultSignupRequestFilter,
} from '../models/adult-signup-request.model';
import { IApiResult } from '../models/api-result.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AdultSignupRequestService {
    lang$ = new BehaviorSubject('');

    constructor(private http: HttpClient) {}

    signUp(signupRequest: IAdultSignupRequest) {
        return this.http.post<any>(
            `api/v3/adult-signup-requests`,
            signupRequest
        );
    }

    getSignupRequests(
        filter?: IAdultSignupRequestFilter
    ): Observable<HttpResponse<IApiResult>> {
        let url = `/api/v3/adult-signup-requests?`
            .concat('page=', (filter?.page || 1).toString())
            .concat('&limit=', (filter?.limit || 1000).toString());

        // if (filter?.search && filter?.search?.length) {
        //     url = url.concat('&search=', filter.search);
        // }

        // if (filter?.dataValidity && filter?.dataValidity?.length) {
        //     url = url.concat(`&${key}=${filterValue}`);
        // }

        if(filter?.signUpStatus !== undefined){
            url = url.concat(`&status=${filter?.signUpStatus}`);
        }
        
        return this.http.get<IApiResult>(url, { observe: 'response' });
    }

    getSignupRequest(requestId: string): Observable<IApiResult> {
        return this.http.get<IApiResult>(
            `/api/v3/adult-signup-requests/${requestId}`
        );
    }

    patchSignupRequest(requestId: string, signupRequest: IAdultSignupRequest) {
        return this.http.patch<any>(
            `api/v3/adult-signup-requests/${requestId}`,
            signupRequest
        );
    }

    approveSignupRequest(
        requestId: string,
        toSendCredentialSMSOnApproval: boolean,
        actionNote: string
    ) {
        return this.http.patch<any>(
            `api/v3/adult-signup-requests/${requestId}`,
            { status: 'APPROVED', toSendCredentialSMSOnApproval, actionNote }
        );
    }

    rejectSignupRequest(requestId: string, actionNote: string) {
        return this.http.patch<any>(
            `api/v3/adult-signup-requests/${requestId}`,
            { status: 'REJECTED', actionNote }
        );
    }
}
