/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable prettier/prettier */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '@portal/app.component';
import { ISelectOption, dayOfWeekOptions } from '@portal/shared/models/select-option.model';
import { ITypeaheadSelectConfig } from '@portal/shared/models/typeahead-select.model';
import { ComponentBase } from '../component-base';
import { OrderTypes } from '@portal/merchant/models/order-types';
import { ICustomizeSelectionColumn } from '@portal/shared/models/customize-column.model';
import { AuthService } from '@portal/auth/services/auth.service';

@Component({
  selector: 'app-filter-and-actions',
  templateUrl: './filter-and-actions.component.html',
  styleUrls: ['./filter-and-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterAndActionsComponent extends ComponentBase implements OnInit {
  @Input() isAdmin = false;
  @Input() isSuperCanteenUser = false;
  @Input() isExport = true;
  @Input() isExportAll = false;
  @Input() isCustomize = false;
  @Input() isReport = false;
  @Input() disableReport = false;
  @Input() isFiltered = false;
  @Input() isAdded = false;
  @Input() isBulkUpload = false;
  @Input() showBtnFilter = true;
  @Input() filterByDayOfWeek = false;
  @Input() orderCardBtn = false;
  @Input() enableShowMore = false;
  @Input() isFoodOrdersPage = false;
  @Input() isMerchantsPage = false;
  @Input() showIcon = true;
  @Input() showPaymentToken = true;
  @Input() showDeviceType = true;
  @Input() showFeeType = true;
  @Input() showSchoolType = true;
  @Input() showInvoiceStatus = true;

  // Text and Placeholder
  @Input() addText = 'New Item';
  @Input() bulkText = 'Upload Bulk App Users';
  @Input() statusPlaceHolder = 'Select Status';
  @Input() reportText = 'Download Report';
  @Input() filterPlaceholder = 'Filter by Master Food Item';
  @Input() filteredByDate = '';

  // Form and Options
  @Input() form: any;
  @Input() schoolOptions: any;
  @Input() canteenOptions: any;
  @Input() genderOptions: any;
  @Input() studentOptions: any;
  @Input() couponOptions: any;
  @Input() adultOptions: any;
  @Input() businessUserTypeOptions: any;
  @Input() themeOptions: any;
  @Input() accessLevelOptions: any;
  @Input() availAmountOptions: any;
  @Input() cardNumberOptions: any;
  @Input() dailyLimitOptions: any;
  @Input() purposeOfRefundOptions: any;
  @Input() bankOptions: any;
  @Input() numberOfDependsOptions: any;
  @Input() cardStatusOptions: any;
  @Input() invoicesStatusOptions: any;
  @Input() orderStatusOptions: any;
  @Input() statusOptions: any;
  @Input() signUpStatusOptions: any;
  @Input() canteenInventoryOptions: any;
  @Input() deviceOptions: any;
  @Input() availQuantityOptions: any;
  @Input() redeemForOptions: any;
  @Input() publishStatusOptions: any;
  @Input() foodCategoryOptions: any;
  @Input() gradeOptions: any;
  @Input() gradeDropdownSettings: any;
  @Input() sectionOptions: any;
  // Digital Card-...
  @Input() categoryOptions: any;
  @Input() vendorOptions: any;
  @Input() digitalCardItemOptions: any; // Digital Card Orders
  @Input() vatMechanismOptions: any; // Digital Card Orders
  @Input() cardOrderTypeOptions: any;
  // Food Orders
  @Input() paymentMethodOptions: any;
  @Input() paymentTokenOptions: any;
  // Food Items
  @Input() sortByBannedListOptions: any;
  @Input() masterFoodItemOptions: any;
  // Attendances
  @Input() earlyStatusOptions: any;
  @Input() lateStatusOptions: any;
  @Input() absentStatusOptions: any;
  @Input() attendeeCheckinStatusOptions: any;
  @Input() attendeeCheckoutStatusOptions: any;
  @Input() checkInUsingOptions: any;
  @Input() checkOutUsingOptions: any;
  // Call Logs
  @Input() calledByOptions: any;
  @Input() pickedUpByOptions: any;
  @Input() dismissedByOptions: any;
  // Location
  @Input() cityOptions: any;
  // Fees
  @Input() feeTypeOptions: any;
  // Offers
  @Input() couponForOptions: any;
  @Input() discountTypeOptions: any;
  // Notification History
  @Input() titleOptions: any;
  // User Common
  @Input() payByFaceOptions: any;
  @Input() hasFacePicOptions: any;
  // Merchants
  @Input() merchantOptions: any;
  @Input() merchantBranchOptions: any;
  @Input() merchantBranchInventoryOptions: any;
  @Input() merchantEventOptions: ISelectOption[] | undefined;
  // Adults
  @Input() accountTypeOptions: any;
  // Money Request
  @Input() purposeOfTransferOptions: any;
  @Input() moneyRequestsStatusOptions: any;
  // Transaction Types
  @Input() transactionTypesOptions: any;

  // Typing config
  @Input() digitalCardStoreTypeaheadSelectConfig!: ITypeaheadSelectConfig;
  @Input() adultTypeaheadSelectConfig!: ITypeaheadSelectConfig;
  @Input() fromUserTypeaheadSelectConfig!: ITypeaheadSelectConfig | null;
  @Input() toUserTypeaheadSelectConfig!: ITypeaheadSelectConfig | null;

  // Multiple selection
  @Input() multipleCanteen = true;
  @Input() multipleMerchant = false;
  @Input() multipleMerchantEvent = false;
  @Input() multipleMerchantBranch = false;
  @Input() multipleMerchantBranchInventory = false;
  @Input() multipleSchool = true;
  @Input() multipleStatus = false;
  @Input() multipleGrade = false;
  @Input() multipleSection = false;

  // Canteen Food Orders
  @Input() hasPaidOrder = false
  @Input() isMarkAllOnline = false;
  @Input() enableToMarkAllOnlineOrdersDelivered = false;
  @Input() enableToMarkAllOnlineOrdersRejected = false;
  @Input() isGetTodayOrders = false;
  @Input() toggleTodayOrderFilter = false;
  @Input() orderTypeOptions: any

  @Input() defaultColumns: ICustomizeSelectionColumn[] = []

  // Clear filter
  @Input() schoolsFilterClearable = true;

  @Output() onMarkAllRejectedClick = new EventEmitter();
  @Output() onMarkAllDeliveredClick = new EventEmitter();
  @Output() getTodayOrders = new EventEmitter();
  @Output() filterOrderType = new EventEmitter();

  @Output() filterRecord = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() exportAll = new EventEmitter();
  @Output() customize = new EventEmitter();
  @Output() report = new EventEmitter();
  @Output() bulkUpload = new EventEmitter();
  @Output() openOrderCardDialog = new EventEmitter();
  @Output() showMoreOptions = new EventEmitter();
  @Output() pendingOrdersReport = new EventEmitter();
  @Output() printOrders = new EventEmitter();

  @ViewChild('search') seacrhInput!: ElementRef;

  formControlName!: string

  public app = AppComponent;
  public dayOfWeekOptions = dayOfWeekOptions;
  public orderTypes = OrderTypes;

  public totalNoOfColumns: number = 0
  public isCanteen = false;


  constructor(private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.isCanteen = this.authService.isCanteenOrSuperCanteen();
    this.formControlName = this.isMerchantsPage ? 'merchant' : 'canteen';
    this.totalNoOfColumns = this.calcTotalNoOfColumns();
  }

  public filteringWithTimeOut = () => {
    setTimeout(() => {
      this.filterRecord.emit();
    }, 3000);
  }

  public foucsOnSearch() {
    this.seacrhInput.nativeElement.focus();
  }

  public clearSearch(): void {
    this.form.get('search')?.setValue('');
    this.form.get('search')?.updateValueAndValidity();
  }

  public getMRColorByStatus = (status: string): string => {
    switch (status) {
      case 'ACCEPTED':
        return 'accepted';
      case 'TRANSFERRED':
        return 'transferred';
      case 'REJECTED':
        return 'rejected';
      case 'PENDING':
        return 'pending';
      case 'CANCELLED':
        return 'cancelled'
      default:
        return '';
    }
  }

  public dummySearchLog(): void {
    console.log('Dummy Search Bar')
  }

  public onColumnCheckBoxClick(event: any, index: any) {
    this.defaultColumns[index].visible = !this.defaultColumns[index].visible
    this.totalNoOfColumns = this.calcTotalNoOfColumns()
    
    if (this.defaultColumns[0].value.includes('List')) {
      const grid = JSON.parse(localStorage.getItem('grid') || '{}');
      grid[this.defaultColumns[0].value] = this.defaultColumns
      localStorage.setItem('grid', JSON.stringify(grid))
    }
    localStorage.setItem('columns', JSON.stringify(this.defaultColumns))
  }

  public calcTotalNoOfColumns(): number {
    let total = 0
    this.defaultColumns.forEach((col) => {
      if (col.visible) {
        total += 1
      }
    })

    return total
  }
}
