export function capitalizeFormat(value: string): string {
    if (!value) {
        return '';
    }
    return value.replace(/([A-Z 1-9])/g, ' $1').trim();
}

export function capitalizeFirstLetter(value: string): string {
    if (!value) {
        return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isNumber(char: string): boolean {
    if (!char?.length) {
        return false;
    }

    return /[0-9]/.test(char);
}

export function extractNumber(value: string): number | null {
    const matched = value?.match(/\d+/);
    if (!matched) {
        return null;
    }
    return isNumber(matched[0]) ? parseInt(matched[0]) : null;
}

export function isEmptyString(value: any): boolean {
    return (typeof value === 'string' || value instanceof String) && value.trim().length === 0;
}

export function isNullOrEmptyString(value: any): boolean {
    return !value || isEmptyString(value);
}

export function removeSpanTag(text: string): string {
    if (!text?.length) {
        return '';
    }
    return text.replace(/<span[^>]*>([^<]+)<\/span>/g, '');
};