import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ILoginResult, IOtpLoginResult } from "@portal/auth/models/login-result.model";
import { Credentials, IForgotPassword, ILoginOTP } from "@portal/shared/models/user.model";
import { CustomHttpUrlEncodingCodec, IAuthLoginService } from "./i-auth-login.service";
import { IApiResult } from "@portal/shared/models/api-result.model";

@Injectable({
  providedIn: 'root',
})
export class AuthLoginService implements IAuthLoginService {
  protected basePath = '/api/v3/users';
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) { }

  public loginByEmail({ email, password }: Credentials): Observable<ILoginResult> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('type', 'EMAIL');

    let headers = this.defaultHeaders;
    headers = headers.set('Content-Type', 'application/json');
    const credentials = JSON.stringify({ email, password });

    return this.httpClient.post<ILoginResult>(`${this.basePath}/login`, credentials, {
      params: queryParameters,
      headers: headers
    });
  }

  public verifyLoginOTP(loginOtp: ILoginOTP): Observable<IOtpLoginResult> {
    return this.httpClient.post<IOtpLoginResult>(`${this.basePath}/login-otp-verification`, loginOtp);
  }

  public resendLoginOTP(loginOtp: ILoginOTP): Observable<IOtpLoginResult> {
    return this.httpClient.post<IOtpLoginResult>(`${this.basePath}/resend-otp`, loginOtp);
  }

  public forgotPassword(request: IForgotPassword, step: string = '1'): Observable<IApiResult> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('type', 'EMAIL');
    queryParameters = queryParameters.set('step', step);


    return this.httpClient.patch<IApiResult>(`${this.basePath}/forgot-password?step=${step}&type=EMAIL`, request);
  }

  public getMyProfile(): Observable<IApiResult> {
    return this.httpClient.get<IApiResult>(`${this.basePath}/me`);
  }
}