import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class QRCodeService {
    constructor(private httpClient: HttpClient) { }

    public updateSettings(studentId: string, payload: any): Observable<IApiResult> {
        return this.httpClient.patch<IApiResult>(`/api/v3/students/${studentId}`, payload);
    }
}